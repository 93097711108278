<template>
  <div :class="'container big-screen ' + pageClass">
    <div class="container-wrap">
      <h5 class="container-title" @click="toggleFullScreen">
        名优现代渔业产业园指挥中心大数据平台
        <span class="time-num">{{ timerNum }}</span>
      </h5>
      <router-view></router-view>
      <div class="container-tab">
        <containerTab />
      </div>
    </div>

    <!-- 百度地图位置 -->
    <baiduMapComponent />
    <div id="global_canvas" v-if="pageClass === 'logistics-trade'"></div>

    <el-dialog
      class="vr-dialog"
      title="实景VR"
      :width="'100%' || app.fontHandle(1600, 'px')"
      height="100vh"
      top="0vh"
      :visible.sync="dialogVRVisible"
    >
      <iframe
        v-if="dialogVRVisible"
        width="100%"
        :height="1080 - 45 + 'px'"
        :src="vrUrl || 'https://720yun.com/t/54vkzm8h079?scene_id=83072189'"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog } from 'element-ui'
Vue.use(Dialog)

import containerTab from '../../components/container-tab.vue'

export default {
  name: 'zhanyu',
  components: {
    containerTab,
    baiduMapComponent: () =>
      import(
        /* webpackChunkName: "baidumap" */ './modules/baidu-map-component.vue'
      ),
  },
  provide() {
    return {
      app: this,
    }
  },
  data() {
    return {
      dialogVRVisible: false, // vr弹窗
      screenfull: null,
      dialogFormVisible: false,
      map: null, // 百度地图引用
      // areaInfo: {}, // 地图详情数据
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
    }
  },
  methods: {
    toVr(url) {
      console.log('toVr', url)
      this.dialogVRVisible = true
      this.vrUrl = url
    },
    // 右上角时间
    loadTimer() {
      this.timerNum = this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      setTimeout((_) => {
        this.loadTimer()
      }, 1000 * 1)
    },
    toggleFullScreen() {
      this.screenfull && this.screenfull.toggle()
      this.screenfull.isFullscreen && location.reload()
    },
    fontHandle(num, unit) {
      /**
       * @desc    计算当前 rem 系数，可用于字号或其他需要等比适应的操作
       * @param   {Number}    num
       *
       * @return  {Number}
       */
      let docEl = document.documentElement
      let clientWidth = docEl.clientWidth
      if (!clientWidth) return

      // rem与px相转换的比例系数
      let remUnit = 100
      // UI设计稿宽度
      let designWidth = 2560

      let standardFontSize = (clientWidth / (designWidth / remUnit)) * 1
      let endNum = standardFontSize * (num / 100).toFixed(2) * 1
      return unit ? endNum + unit : endNum
    },
  },
  mounted() {
    // document.querySelector('#app').style.overflow = 'hidden'
    import(/* webpackChunkName: "screenfull" */ 'screenfull').then(
      (screenfull) => {
        this.screenfull = screenfull
        // if (!screenfull.isEnabled) {
        //   console.log('不允许全屏', screenfull)
        //   return false
        // }
        // try {
        //   console.log('允许全屏', screenfull)
        //   screenfull.toggle(document.querySelector('#app'))
        // } catch (error) {
        //   console.log('error全屏', screenfull)
        // }
      }
    )
  },
  created() {
    this.loadTimer()
    document.title = '名优现代渔业产业园指挥中心'
  },
  computed: {
    pageClass: function () {
      return this.$route.name.replace(/[A-Z]/g, function (match) {
        return '-' + match.toLowerCase()
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
